<template>
  <div>
    <c-table
      ref="tasktable"
      title="관련 아차사고 목록"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      selection="multiple"
      rowKey="iimAccidentId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addTask" />
          <c-btn v-if="editable && grid.data && grid.data.length>0" label="삭제" icon="remove" @btnClicked="deleteTask" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'riskNearmissAccident',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
      }),
    },
    task: {
      type: Object,
      default: () => ({
        insertUrl: '',
        deleteUrl: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "사업장",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearAccidentNo",
            field: "iimNearAccidentNo",
            label: "아차사고번호",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearAccidentName",
            field: "iimNearAccidentName",
            label: "아차사고명",
            style: 'width:200px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "processName",
            field: "processName",
            label: "공정",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "iimStatusNearName",
            field: "iimStatusNearName",
            style: 'width:100px',
            label: "진행상태",
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearKindName",
            field: "iimNearKindName",
            style: 'width:120px',
            label: "아차사고유형",
            align: "left",
            sortable: true,
          },
          {
            name: "iimGradeName",
            field: "iimGradeName",
            label: "아차사고등급",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "occurrenceLocation",
            field: "occurrenceLocation",
            label: "발생가능장소",
            style: 'width:100px',
            align: "left",
            sortable: true,
          },
          {
            name: "occurrenceDt",
            field: "occurrenceDt",
            style: 'width:100px',
            label: "발생일",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.iim.accident.near.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      console.log(this.grid.data.length)
    },
    linkClick(row) {
      this.popupOptions.title = "아차사고 상세"; // 아차사고 상세
      this.popupOptions.param = {
        iimNearAccidentId: row ? row.iimNearAccidentId : '',
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/iim/near/nearAccidentDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    deleteTask() {
      let selectData = this.$refs['tasktable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '제외할 행을 지정하세요.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '제외하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.task.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(this.$_.map(selectData, (item) => {
                return {
                  ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
                  iimAccidentId: item.iimAccidentId,
                }
              }))
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$refs['tasktable'].$refs['compo-table'].clearSelection();
              this.getList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addTask() {
      this.popupOptions.title = '순회점검 검색'; // 순회점검 검색
      this.popupOptions.param = {
        type: 'multiple',
        accidentKind: 'NEAR',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/iim/accidentPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTaskPopup;
    },
    closeTaskPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let insertList = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { iimAccidentId: item.iimAccidentId }) === -1) {
            insertList.push({
              ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
              iimAccidentId: item.iimAccidentId,
            });
          }
        })

        this.$http.url = this.task.insertUrl;
        this.$http.type = 'POST';
        this.$http.param = insertList
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
  }
};
</script>